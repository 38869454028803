<script setup lang="ts">
import type { User } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { UAParser } from "ua-parser-js";
import { z } from "zod";
import { processError, successToast } from "~/lib/utils/errors";
import { userFieldConfigs, userFormSchema } from "~/lib/utils/forms";
import CheckboxPreference from "../fields/CheckboxPreference.vue";
import type { ConfigItem } from "../ui/auto-form";

const omniaSdk = useOmniaSdk();

const open = defineModel<boolean>("open");
const emit = defineEmits(["success"]);

const props = defineProps<{
  user: User;
}>();

const { data: sessionsData, refresh: refreshSessions } = useAsyncData("user-sessions", async () => {
  try {
    return await omniaSdk.auth.user.sessions();
  } catch (error) {
    processError(error);
  }
});

const { data: currentToken } = useAsyncData("current-token", async () => {
  try {
    return await omniaSdk.auth.user.token();
  } catch (error) {}
});

const sessions = computed(() => {
  if (!sessionsData.value) return [];

  const currentTokenId = currentToken.value?.accessToken.split("|")[0] || "";

  return sessionsData.value.data.map((session) => ({ ...session, info: new UAParser(session.name).getResult(), is_current: session.id.toString() === currentTokenId }));
});

const formSchema = z
  .object(userFormSchema)
  .omit({ email: true })
  .extend({
    password: z
      .string()
      .min(8, "La password deve essere di almeno 8 caratteri")
      .regex(/[A-Z]/, "La password deve contenere almeno una lettere maiuscola")
      .regex(/[a-z]/, "La password deve contenere almeno una lettere minuscola")
      .regex(/[0-9]/, "La password deve contenere almeno un numero")
      .regex(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "La password deve contenere almeno un carattere speciale (!, @, ?, ecc.)")
      .describe("Nuova password")
      .nullish(),
    password_confirmation: z.string().describe("Ripeti password").nullish(),
    has_newsletter: z.boolean().optional(),
    has_marketing: z.boolean().optional(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Le password devono coincidere",
    path: ["password_confirmation"],
  });

const form = useForm({ validationSchema: toTypedSchema(formSchema) });

watch(open, (val, old) => {
  if (!old && val) {
    form.setValues(props.user);
  }
});

const fieldConfig: Record<string, ConfigItem> = {
  ...userFieldConfigs,
  password: {
    inputProps: {
      type: "password",
      autocomplete: "new-password",
    },
  },
  password_confirmation: {
    inputProps: {
      type: "password",
      autocomplete: "new-password",
    },
  },
  has_newsletter: {
    label: "Ricevi email del servizio Memoweb",
    component: CheckboxPreference,
  },
  has_marketing: {
    label: "marketing",
    component: CheckboxPreference,
  },
};

const df = new DateFormatter("it-IT", { dateStyle: "long" });

async function onSubmit(values: Record<string, any>) {
  try {
    const { password, password_confirmation, ...userWithoutPassword } = values;

    const user = password?.trim().length ? values : userWithoutPassword;
    await omniaSdk.auth.user.update({
      ...user,
      is_first_login: false,
    });

    successToast();
    emit("success");
  } catch (error) {
    processError(error);
  }
}

const deleteModal = ref({
  opened: false,
  id: null as number | null,
});

async function deleteSession() {
  if (!deleteModal.value.id) return;
  try {
    await omniaSdk.auth.user.removeSession(deleteModal.value.id);
    refreshSessions();

    successToast("Disconnesso con successo");
  } catch (error) {
    processError(error);
  }
}
</script>

<template>
  <Dialog v-model:open="open">
    <DialogScrollContent class="max-w-3xl" @pointer-down-outside.prevent>
      <DialogHeader>
        <DialogTitle>Impostazioni utente</DialogTitle>
        <DialogDescription>{{ user.email }}</DialogDescription>
      </DialogHeader>

      <AutoForm @submit="onSubmit" :form="form" :schema="formSchema" :field-config="fieldConfig" class="content-card-grid grid gap-4">
        <div class="content-card-grid py-4 grid !gap-2 mb-4">
          <h5 class="font-bold">Sessioni attive</h5>
          <div class="grid divide-y" v-if="sessionsData">
            <div class="flex gap-2 py-3 justify-between items-start" v-for="session in sessions" :key="session.id">
              <div class="flex flex-col gap-1">
                <span>
                  <template v-if="session.info.browser.name"> {{ session.info.browser.name }} {{ session.info.browser.version }} su {{ session.info.os.name }} {{ session.info.os.version }} </template>
                  <template v-else>
                    {{ session.info.ua }}
                  </template>
                </span>
                <span class="text-sm text-muted-foreground" :class="session.is_current && 'font-bold'">
                  <template v-if="session.is_current">Sessione corrente</template>
                  <template v-else-if="session.last_used_at">Ultimo utilizzo: {{ df.format(session.last_used_at) }}</template>
                </span>
              </div>
              <div v-if="!session.is_current">
                <Button variant="ghost" type="button" size="icon" @click="deleteModal = { opened: true, id: session.id }"><Icon name="carbon:close" class="h-4 w-4" /></Button>
              </div>
            </div>
          </div>
          <Skeleton v-else class="h-32" />
        </div>

        <DialogFooter class="mt-4">
          <DialogClose as-child>
            <Button type="reset" variant="ghost">Chiudi</Button>
          </DialogClose>
          <Button type="submit" :disabled="!form.meta.value.valid">Salva</Button>
        </DialogFooter>
      </AutoForm>
    </DialogScrollContent>
  </Dialog>

  <AlertDialog v-model:open="deleteModal.opened">
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Terminare la sessione?</AlertDialogTitle>
        <AlertDialogDescription>Verrai disconnesso dal dispositivo selezionato</AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Annulla</AlertDialogCancel>
        <AlertDialogAction variant="destructive" @click="deleteSession">Termina</AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
